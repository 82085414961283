#create-post-button {
	width: 100px;
	text-align: center;
	padding: 10px;
	border: 1px solid black;
	cursor: pointer;
}
#create-post-button a {
	text-decoration: none;
}
@media only screen and (min-width: 700px) {
	#create-post-button {
		float: right;
		width: 150px;
		margin-right: 70px;
		margin-top: 20px;
		padding: 5px;
	}
}
