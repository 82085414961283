.sortForm {
	float: right;
	display: flex;
	flex-direction: column;
	text-align: right;
	padding: 10px;
}
.sortForm select {
	width: 100px;
	border: 1px solid black;
}
