.siteNav ul form input {
	width: 60%;
	height: 25px;
	box-sizing: border-box;
	padding: 5px;
}
.siteNav ul form button {
	box-sizing: border-box;
	padding: 3px;
	width: 70px;
	margin-left: 10px;
	vertical-align: middle;
}
