.mobile-home-page-container h3 {
	width: 100%;
	height: 45px;
	color: white;
	padding: 10px;
	border: 1px solid black;
	background-color: blue;
	text-align: center;
}
.mobile-home-page-events-closed {
	display: none;
}
.mobile-home-page-events-open {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}
.mobile-home-page-new-posts-closed {
	display: none;
}
.mobile-home-page-new-posts-open {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}
.home-page-container {
	display: none;
}

@media only screen and (min-width: 700px) {
	.mobile-home-page-container {
		display: none;
	}
	.home-page-container {
		display: block;
	}
	.home-page-content {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: flex-start;
	}
	#home-page-newest-post {
		width: 100%;
		margin-left: 25px;
	}
	#home-page-newest-post h3 {
		margin-left: 40px;
	}
	#home-page-upcoming-events {
		width: 100%;
	}
}
