.post-container {
	width: 100%;
}
.post-content {
	width: 95%;
	margin-top: 10px;
	padding: 10px;
	margin-left: auto;
	margin-right: auto;
}
.comment-like-button-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.comment-like-button-container button {
	cursor: pointer;
	margin: 10px;
	width: 50%;
	height: 25px;
	font-size: 13px;
	border: 1px solid black;
}
.edit-button-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.edit-button-container button {
	cursor: pointer;
	margin: 10px;
	width: 50%;
	height: 25px;
	font-size: 13px;
	border: 1px solid black;
}

@media only screen and (min-width: 700px) {
	.post-container {
		width: 100%;
	}
	.post-content {
		width: 95%;
		margin-top: 10px;
		padding: 10px;
		margin-left: auto;
		margin-right: auto;
	}
	.comment-like-button-container {
		width: 20%;
		display: flex;
		justify-content: space-between;
	}
	.comment-like-button-container button {
		cursor: pointer;
		margin: 10px;
		width: 50%;
		height: 25px;
		font-size: 13px;
		border: 1px solid black;
	}
	.edit-button-container {
		float: right;
		width: 20%;
		display: flex;
		justify-content: space-between;
	}
	.edit-button-container button {
		cursor: pointer;
		margin: 10px;
		width: 50%;
		height: 25px;
		font-size: 13px;
		border: 1px solid black;
	}
}
