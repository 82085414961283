.newPost-container {
	width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}
.newPost-container h3 {
	text-align: center;
	font-size: 25px;
}

.newest-posts {
	width: 95%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
	font-size: 18px;
	border: 1px solid black;
	margin-bottom: 20px;
}
.newest-posts a {
	margin: 0;
	text-decoration: none;
}
.newest-posts a:hover {
	color: green;
}
.postInfo {
	padding: 10px;
	line-height: 5px;
}

@media only screen and (min-width: 700px) {
	.newPost-content {
		display: flex;
		flex-direction: column;
		margin-top: 25px;
	}
	.newPost-container h3 {
		text-align: left;
		font-size: 20px;
		margin-top: 35px;
		margin-left: 75px;
	}
	.newest-posts {
		width: 90%;
		padding: 15px;
		font-size: 16px;
		float: left;
	}
}
