.thisWeek-container {
	width: 100%;
}
.thisWeek-content {
	padding: 10px;
	font-size: 18px;
}
.thisWeek-content h3 {
	text-align: center;
}
.event {
	width: 100%;
	height: auto;
	font-size: 15px;
	border: 2px black solid;
	margin-bottom: 10px;
	padding: 15px;
	color: rgb(0, 0, 0);
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: left;
}
.event p {
	width: 70%;
}
.event span {
	width: 100%;
	line-height: 15px;
}
.event a {
	color: green;
	text-decoration: none;
}
.event a:hover {
	color: white;
}

@media only screen and (min-width: 700px) {
	.thisWeek-content {
		margin-right: 20px;
	}
	.thisWeek-content h3 {
		text-align: right;
	}
	.event {
		font-size: 18px;
	}
}
