.signUp-container {
	width: 100%;
	background-color: blue;
	height: auto;
	padding-top: 10px;
	padding-bottom: 30px;
}
.signUp-content {
	width: 100%;
	height: auto;
	padding: 25px;
	margin-left: auto;
	margin-right: auto;
}
.signUp-content h3 {
	color: white;
	text-align: center;
	font-size: 25px;
}
.signUp-content form {
	display: flex;
	flex-direction: column;
	border: 5px solid lightgreen;
	padding: 20px;
	background-color: yellow;
}

.signUp-content form input {
	box-sizing: border-box;
	height: 30px;
	font-size: 18px;
	padding: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	border: 1px solid black;
}
.signUp-content form button {
	height: 40px;
	margin-top: 15px;
	cursor: pointer;
	border: 1px solid black;
	background-color: blue;
	color: white;
	font-size: 20px;
}

@media only screen and (min-width: 700px) {
	.signUp-container {
		padding-top: 5px;
		padding-bottom: 50px;
	}
	.signUp-content {
		width: 30%;
		margin-top: 5px;
	}
}
