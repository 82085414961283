.events-calendar-container {
	margin: 0;
	width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}
.calendar-content ul {
	list-style-type: none;
	margin: 0;
}
.month {
	padding: 10px 10px;
	width: 100%;
	height: 50px;
	background: #2600ff;
	text-align: center;
}

.month ul {
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
}

.month ul li {
	color: white;
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: 5px;
}

#weekdays {
	margin: 0;
	padding: 10px 0;
	background-color: #ddd;
	display: flex;
	justify-content: space-around;
}

#weekdays li {
	color: #666;
	text-align: center;
}

#days {
	padding: 0;
	background: #eee;
	margin: 0;
	display: grid;
	grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
	cursor: pointer;
}

#days li {
	list-style-type: none;
	text-align: center;
	font-size: 18px;
	color: rgb(0, 0, 0);
	padding: 5px;
	width: 100%;
	height: auto;
	border: 1px solid rgb(76, 0, 255);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	margin: 0;
}

#days li.active {
	background: #eeff00;
	color: rgb(0, 0, 0) !important;
}

#next,
#prev {
	cursor: pointer;
}

@media only screen and (min-width: 700px) {
	.month {
		padding: 15px 25px;
		width: 100%;
		height: 50px;
		background: #2600ff;
	}

	.month ul {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr;
	}

	.month ul li {
		color: white;
		font-size: 20px;
		text-transform: uppercase;
		letter-spacing: 3px;
	}

	#weekdays {
		width: 100%;
		margin: 0;
		padding: 10px 0;
		background-color: #ddd;
		display: flex;
		justify-content: space-around;
	}

	#weekdays li {
		color: #666;
		text-align: center;
	}

	#days {
		padding: 0;
		background: #eee;
		margin: 0;
		display: grid;
		grid-template-columns: auto auto auto auto auto auto auto;
		cursor: pointer;
	}

	#days li {
		list-style-type: none;
		text-align: center;
		font-size: 16px;
		color: rgb(0, 0, 0);
		padding: 5px;
		width: 100%;
		height: auto;
		border: 1px solid rgb(76, 0, 255);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		margin: 0;
	}
	#days li a {
		width: 100%;
	}

	#days li.active {
		background: #eeff00;
		color: rgb(0, 0, 0) !important;
	}

	#next,
	#prev {
		cursor: pointer;
	}
}
