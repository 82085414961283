.landingPage-container {
	text-align: center;
	padding: 15px;
}
.landingPage-content img {
	width: 100%;
}
@media only screen and (min-width: 700px) {
	.landingPage-container {
		padding: 15px;
	}
}
