.logIn-container {
	width: 100%;
	height: auto;
	padding-top: 5px;
	padding-bottom: 50px;
	background-color: blue;
}
.logIn-content {
	width: 100%;
	padding: 25px;
	margin-left: auto;
	margin-right: auto;
}
.logIn-content h3 {
	color: white;
	font-size: 25px;
	text-align: center;
}
.logIn-content form {
	display: flex;
	flex-direction: column;
	border: 5px solid lightgreen;
	padding: 20px;
	background-color: yellow;
}
.logIn-content form input {
	margin-top: 10px;
	margin-bottom: 10px;
	height: 35px;
	font-size: 18px;
	padding: 5px;
	border: 1px solid black;
}
.logIn-content form button {
	height: 35px;
	font-size: 20px;
	padding: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	border: 1px solid black;
	background-color: blue;
	color: white;
}

@media only screen and (min-width: 700px) {
	.logIn-content {
		width: 35%;
	}
}
