.directory-container {
	width: 100%;
}
.directory-container h2 {
	text-align: center;
}
.directory-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.directory-listing h4 {
	padding: 0px;
	margin: 0px;
}
.directory-listing ul {
	list-style: none;
}
@media only screen and (min-width: 700px) {
	.directory-container {
		width: 100%;
	}
	.directory-container h2 {
		text-align: left;
		margin-left: 70px;
	}
	.directory-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.directory-listing h4 {
		padding: 0px;
		margin: 0px;
	}
	.directory-listing ul {
		list-style: none;
	}
}
