.add-comment-container {
	width: 100%;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
}
.add-comment-container form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.add-comment-container textarea {
	width: 100%;
	height: 250px;
	height: 100px;
}
.add-comment-container span {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.add-comment-container button {
	width: 30%;
	height: 35px;
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 5px;
	border: 1px solid black;
}

@media only screen and (min-width: 700px) {
	.add-comment-container {
		margin: 0;
	}

	.add-comment-container textarea {
		height: 200px;
	}
	.add-comment-container span {
		width: 50%;
	}
	.add-comment-container button {
		width: 20%;
		margin: 10px;
	}
}
