.create-event-container {
	width: 100%;
}
.create-event-content {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-size: 20px;
}
.create-event-content form {
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.create-event-content form input {
	width: 90%;
	height: 45px;
	font-size: 20px;
	text-align: center;
	padding: 5px;
	margin: 10px;
	border: 1px solid black;
}
.create-event-content form select {
	width: 90%;
	height: 45px;
	font-size: 20px;
	text-align: center;
	padding: 5px;
	margin: 10px;
	border: 1px solid black;
}
.create-event-content textarea {
	width: 90%;
	height: 200px;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	border: 1px solid black;
}
.create-event-content button {
	cursor: pointer;
	width: 90%;
	margin: 10px;
	height: 45px;
	font-size: 20px;
	border: 1px solid black;
}
@media only screen and (min-width: 700px) {
	.create-event-container {
		width: 100%;
	}
	.create-event-content {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		font-size: 18px;
	}
	.create-event-content form {
		width: 100%;
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	.create-event-content form input {
		width: 50%;
		height: 35px;
		font-size: 18px;
		text-align: center;
		padding: 5px;
		margin: 10px;
	}
	.create-event-content form select {
		width: 50%;
		height: 35px;
		font-size: 18px;
		text-align: center;
		padding: 5px;
		margin: 10px;
		border: 1px solid black;
	}
	.create-event-content textarea {
		width: 100%;
		height: 150px;
		font-size: 16px;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.create-event-content button {
		cursor: pointer;
		width: 200px;
		margin: 10px;
		height: 30px;
	}
}
