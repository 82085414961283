.post-edit-container {
	width: 100%;
	margin-top: 25px;
	padding: 10px;
	margin-left: auto;
	margin-right: auto;
}

.post-edit-container input {
	width: 100%;
	font-size: 20px;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	border: 1px solid black;
}
.post-edit-container textarea {
	width: 100%;
	height: 250px;
	margin-top: 10px;
	padding: 10px;
	font-size: 20px;
	border: 1px solid black;
}

.post-edit-container span {
	margin-top: 10px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.post-edit-container button {
	cursor: pointer;
	margin: 10px;
	width: 50%;
	height: 35px;
	font-size: 16px;
	padding: 5px;
	border: 1px solid black;
}

@media only screen and (min-width: 700px) {
	.post-edit-container span {
		width: 30%;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.post-edit-container button {
		cursor: pointer;
		width: 100%;
		height: 35px;
		font-size: 16px;
		padding: 5px;
		border: 1px solid black;
	}
}
