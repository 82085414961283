.forum-container {
	width: 100%;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.forum-container h3 {
	text-align: center;
}

.forum-content {
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.forum-content ul {
	list-style: none;
	padding: 0px;
}
.forum-content ul h4 {
	background-color: mediumblue;
	color: white;
	height: 50px;
	margin: 0;
	padding: 15px;
	text-align: center;
}
.forum-content li {
	background-color: lightgray;
	border: 1px solid black;
	padding: 10px;
	margin: 0px;
	height: auto;
	font-size: 18px;
	line-height: auto;
}
.forum-content li a {
	text-align: center;
	text-decoration: none;
}

@media only screen and (min-width: 700px) {
	.forum-container {
		font-size: 18px;
		display: inline-block;
	}
	.forum-container h3 {
		margin-left: 70px;
		float: left;
	}
	.forum-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 25px;
	}
	.forum-content ul {
		list-style: none;
	}
	.forum-content li {
		background-color: lightgray;
		border: 1px solid black;
		padding: 10px;
		padding-bottom: 0px;

		height: auto;
		font-size: 14px;
		line-height: 1em;
	}
}
