.create-post-container {
	width: 100%;
}
.create-post-content {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 25px;
}
.category-pop-up-container {
	width: 100%;
}
.categoryPopUpForm {
	display: grid;
	grid-template-columns: auto;
	margin-left: 10%;

	margin-bottom: 100px;
}
.categoryPopUpForm input {
	cursor: pointer;
	transform: scale(2);
	margin: 10px;
}
.categoryPopUpForm label {
	vertical-align: middle;
	margin: 10px;
}
.create-post-content form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 20px;
}
.create-post-content form input {
	width: 100%;
	height: 45px;
	margin: 10px;
	padding: 10px;
	font-size: 20px;
	border: 1px solid black;
}
.create-post-content form select {
	width: 100%;
	height: 45px;
	font-size: 20px;
	margin: 10px;
	padding: 10px;
	border: 1px solid black;
}
.create-post-content form textarea {
	width: 100%;
	height: 200px;
	margin: 10px;
	font-size: 20px;
	padding: 5px;
	border: 1px solid black;
}
.create-post-content form button {
	width: 100%;
	height: 45px;
	margin: 10px;
	cursor: pointer;
	border: 1px solid black;
	font-size: 20px;
	padding: 10px;
}
@media only screen and (min-width: 700px) {
	.create-post-container {
		width: 100%;
	}
	.create-post-content {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 25px;
	}
	.category-pop-up-container {
		width: 100%;
	}
	.categoryPopUpForm {
		display: grid;
		grid-template-columns: auto auto auto;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;
	}
	.categoryPopUpForm input {
		cursor: pointer;
		transform: scale(2);
		margin: 10px;
	}
	.categoryPopUpForm label {
		vertical-align: middle;
		margin: 10px;
	}
	.create-post-content form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.create-post-content form input {
		width: 50%;
		height: 35px;
		font-size: 16px;
	}
	.create-post-content form select {
		width: 30%;
		height: 35px;
		font-size: 16px;
	}
	.create-post-content form textarea {
		width: 70%;
	}
	.create-post-content form button {
		width: 30%;
		height: 45px;
		margin: 10px;
		cursor: pointer;
		border: 1px solid black;
		font-size: 20px;
		padding: 10px;
	}
}
